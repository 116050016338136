import { colors } from "@biblioteksentralen/js-utils";
import { Box, Grid, keyframes, Spinner } from "@chakra-ui/react";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import InternalLink from "../components/InternalLink";
import { usePreviewInQueryParams } from "../components/preview/useSanityPreview";
import { useSanityAuth } from "../components/sanityAuth/useSanityAuth";

const Style = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

const StyledH1 = styled.h1`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Header = styled.header`
  background: ${colors.grey45};
  height: 5rem;
`;

const Main = styled.main`
  display: flex;
  gap: 2rem;
  padding: 1rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.footer`
  background: ${colors.grey80};
  height: 10rem;
`;

const delay = keyframes`
  from {
    opacity: 0
  }
`;

function NotFound() {
  const sanityAuth = useSanityAuth();
  const router = useRouter();
  const preview = usePreviewInQueryParams();

  const erForsiden = router.asPath === "/";
  const site = router.query.site;

  if (preview)
    return (
      <Grid placeContent="center" minHeight="100vh">
        <Spinner speed="1s" />
      </Grid>
    );

  return (
    <Box animation={`${delay} .1s .5s backwards`}>
      <Head>
        {/* Fordi vi har en del `fallback: 'blocking' | true` i getStaticProps må vi sørge for at 404-sidene våre ikke blir indeksert. Disse 404-sidene får fortsatt status 200 fra server https://github.com/vercel/next.js/discussions/10960 */}
        <meta name="robots" content="noindex" />
      </Head>
      <Style>
        <Header></Header>
        <Main>
          {erForsiden ? (
            <>
              <StyledH1>«{site}» er ikke et kjent nettsted</StyledH1>
              <p>Har du skrevet adressen riktig?</p>
            </>
          ) : (
            <>
              <StyledH1>Ingenting her 🤷‍♀️</StyledH1>
              <InternalLink href="/">Tilbake til forsiden</InternalLink>
            </>
          )}
          {sanityAuth.isAuthenticated && (
            <p>Hvis du nylig har publisert innhold kan det ta opptil ett minutt før det dukker opp her</p>
          )}
        </Main>
        <Footer></Footer>
      </Style>
    </Box>
  );
}

export default NotFound;
