import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePrevious } from "react-use";
import { loggError } from "../../utils/logging";
import { usePreviewSubscription } from "../../utils/sanity/client";
import { usePreviewContext } from "./previewContext";

export const usePreviewInQueryParams = () => {
  const router = useRouter();
  // Settes vha query-param i url "?preview=true"
  return router.query.preview === "true";
};

export function useSanityPreview<Data>(
  initialData: Data,
  query: string,
  params?: Record<string, any>
): Data | undefined {
  const context = usePreviewContext();
  const previewInQueryParams = usePreviewInQueryParams();

  const prevPreviewInQueryParams = usePrevious(previewInQueryParams);
  useEffect(() => {
    if (!prevPreviewInQueryParams && previewInQueryParams) {
      context.setPreviewMode(true);
    }
  }, [context, previewInQueryParams, prevPreviewInQueryParams]);

  const subscribtion = usePreviewSubscription(query, {
    params,
    initialData,
    enabled: context.isOn,
  });

  subscribtion.error && loggError(subscribtion.error);

  /* When switching preview on and then off again, it still shows draft data although enabled = false
  (maybe it has something to do with the deep comparison in usePreviewSubscription?).
  Therefore we return initialData directly when the preview is off. */
  return context.isOn ? subscribtion.data : initialData;
}
